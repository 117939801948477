import React, { useEffect, useState } from "react";
import Select from "react-select";

import { SlCalender } from "react-icons/sl";
import { AiFillEye } from "react-icons/ai";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import useAxiosFile from "../actions/useaxios";
import { datetimeFormat, isEmpty } from "../../lib/common";
import Table from "../table/table";
import { Link, NavLink, useHistory } from "react-router-dom";
import moment from "moment";




const initdata = {
  tripnumber: "all",
  riderid: "all",
  drivername: "all",
  fromdate: "",
  todate: "",
};

const TripRoute = () => {
  const [startDate, setStartDate] = useState(new Date());
  const axios = useAxiosFile();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [tripRoute, setTrips] = useState([]);
  const [fromdate, setfromDate] = useState("");
  const [todate, settoDate] = useState("");
  const [data, setData] = useState(initdata);
    const [pages, setpages] = useState(1);
const [load, setload] = useState(false);

const history = useHistory()

  useEffect(() => {
    getrides();
  }, [skip,limit,pages,todate,fromdate]);

  const getrides = async (data) => {
    let senddata = {
      status: "ridehistory",
      from: "admin",
      page: skip,
      limit: limit,
      page: pages, fromdate: fromdate,
      todate: todate,
    };
    //
    var resp = await axios.Trips(data ? data : senddata);
   console.log("socket.handshake?.query?._idsocket.handshake?.query?._idsocket.handshake?.query?._id",resp?.data)
    setTrips(resp?.data);
setload(true)

  };

  const onchange = (e) => {
    const { id, value } = e.target;
    setData({ ...data, ...{ [id]: value } });
  };
  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },

    {
      key: "userid", label: "User", html: (cell, row, rowind, colind)=><div>
        {console.log("row",row)}
        {cell}{row.useriddelete ==  '1'?"(deleted user)":''}</div>
    },
    { key: "vehicle", label: "Car type", html: (cell, row, rowind, colind)=><div>{cell?cell:"book any"}</div> },
    { key: "status", label: "Trip Status", html: (cell, row, rowind, colind) => <div>
      {
       ( cell == "cancel" ||cell == "cancelbyuser")? "Cancelled By Rider" :
        cell == "create" ? "Created By Rider" :
        cell == "accept" ? "Accepted By Driver" :
        cell == "cancelbydriver" ? "Cancelled By Driver" :
        cell == "dropoff" ? "Reached to Destination" :
        cell == "endtrip" ? "Trip Completed" :
        cell == "completepayment" ? "Payment Completed" :
        cell == "cancelbyuseronride" ? "Cancell by user while on ride" :
        cell == "cancelbydriveronride" ? "Cancell by driver while on ride" :""

      }
    </div> },
    { key: "createdAt", label: "Trip Date",html: (cell, row, rowind, colind) => <div>


      {
      datetimeFormat(cell)

    }
    </div>
  },

    {
      key: "driverid", label: "Driver",  html: (cell, row, rowind, colind) =>  <div>{row.driverid ? (cell + row.driveriddelete ==  1?"(deleted driver)":cell) : "book any"}</div> },

      { key: "bookingnumber", label: "Booking Number",   html: (cell, row, rowind, colind) => <div>{cell}</div> },
   
    { key: "price", label: "Fare",   html: (cell, row, rowind, colind) => <div>{row.vehicletype ? cell : "book any"}</div> },
   
    { key: "View Invoice", label: "View Invoice",  html: (cell, row, rowind, colind) => <div onClick={()=>history.push("/user-statement",{state:row})}>
     View
      
    </div>
  },





  ];
  const search = async () => {
    let Send = {
      from: "admin",
      status: "ridehistory",
      bookingnumber: data.tripnumber,
      page: skip,
      limit: limit,
      drivername: data.drivername,
      ridername: data.riderid,
      fromdate: fromdate,
      todate: todate,
    };
    getrides(Send);
    //
    // setTrips(resp?.data)
  };

  const reset = () => {
    setData(initdata);
    setfromDate("");
    settoDate("");
    getrides();
  };
  const Pagination = (limit, page) => {
    setpages(page);
    setLimit(limit);
  };

  return (
    <div style={{ color: "black" }}>
      <div className="row">
        <div className="col-12">
          <h4 className="card-title new_headings mt-3  mb-4 ">Trip Route</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-md-4 col-xl-2">
                  <div className="custom-date-picker form-control new_input date_minht ">
                    <div className="row">
                      <div className="col-9">
                        <DatePicker
                          className=" "
                          selected={fromdate?fromdate:startDate}
                          onChange={(date) => setfromDate(date)}
                          value={
                            fromdate?
                            moment(
                              fromdate
                          ).format(
                            "MM-DD-YYYY"
                          )
                          :
                          "From"
                        }
                        maxDate={
                          Date.now()
                        }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-end">
                        <SlCalender style={{ color: "#403C3E" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mt-3 mt-sm-0  col-md-4 col-xl-2">
                  <div className="custom-date-picker form-control new_input date_minht">
                    <div className="row">
                      <div className="col-9">
                        <DatePicker
                          className=" "
                          selected={todate?todate:startDate}
                          onChange={(date) => settoDate(date)}
                          value={
                            todate!=="" ?
                            moment(
                              todate
                          ).format(
                            "MM-DD-YYYY"
                          )
                          :
                          "To"
                        }
                          minDate={
                            fromdate
                        }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-end">
                        <SlCalender style={{ color: "#403C3E" }} />
                      </div>
                    </div>
                  </div>
                </div>
             
               
              </div>
              <div className="row align-items-center mt-3 mx-0">
                <button
                  className="convert_btn reset_btn"
                  onClick={() => search()}
                >
                  Search
                </button>
                <button
                  className="convert_btn reset_btn ml-3"
                  onClick={() => reset()}
                >
                  Reset
                </button>
              </div>



  <div className="row mt-md-3">
          <div className="col-12 grid-margin">
            <div className="card ride_card bor_none">
              <div className="card-body table_card_body">
                <div className="triproute_table_width">
                  <Table
load={load}

                    column={column}
                    data={tripRoute}
                    Pagination={Pagination}
                    excel={true}
                    csv={true}
                    print={true}
                    search={true}
                    fileName={"Riders"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>



      </div>
    </div>
  </div>
</div>
  );
};

export default TripRoute;
